





































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IInputControlDocumentQuality } from '@store/modules/input-control/Interfaces';

import './scss/PopupInputControlDocs.scss';

import { IProject } from '@store/modules/project/Interfaces';
const NSProject = namespace('storeProject');
const NSDocuments = namespace('storeProjectDocuments');

import { namespace } from 'vuex-class';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';

@Component({
    name: 'PopupInputControlDocs',
    components: {
        'BasePopup': () => import('@components/popups/BasePopup.vue'),
    }
})

export default class PopupInputControlDocs extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
    }

    @Prop({}) items!: IInputControlDocumentQuality[];
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_DOC_FILES) getDocFiles!:
        (payload: { appId: number, documentId: string }) => Promise<any>;

    openPopup() {
        this.$refs.basePopup.openPopup();
    }

    closePopup() {
        this.$refs.basePopup.closePopup();
        this.$emit('close');
    }

    openFilePopup(itemData) {
        const data = {
            urlFileView: itemData.files[0]?.view,
            urlFileDownload: itemData.files[0]?.link,
            fileTitle: itemData.files[0]?.name,
            format: itemData.files[0]?.format,
            signed: itemData.files[0]?.signed,
        }
        this.$emit('show-file-preview', data);
    }
}
